<template>
  <div class="csn-dap">
    <div class="modal-dialog csn-modal-dialog">
      <div class="modal-content">
        <button
          class="btn csn-modal-close-button"
          @click="handleCloseButtonClick"
        >
          <CloseIcon />
        </button>
        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('deposit_and_play') }}
          </h5>
        </div>
        <div class="modal-body">
          <Message :type="messageTypeEnum.FAILURE" :message="message" />
          <Form
            class="mx-auto csn-modal-content-form"
            :ref="formRef"
            :id="formId"
          >
            <section class="csn-dap-field-group-container">
              <label for="deposit">
                {{ t('deposit_amount') }}
              </label>
              <Message
                :type="messageTypeEnum.FAILURE"
                :message="serverError[name.DEPOSIT]"
                class="csn-dap-deposit-message"
              />
              <div
                class="csn-deposit-example-btn-container csn-dap-example-btn-container"
              >
                <button
                  v-for="(amount, index) in exampleAmountList"
                  :key="index"
                  @click="handleExampleAmountClick(amount)"
                  class="btn casino-btn csn-deposit-example-btn csn-dap-example-btn"
                >
                  {{ amount }}
                </button>
              </div>
              <MoneyCounter
                :name="name.DEPOSIT"
                v-model="formData[name.DEPOSIT]"
                :min="1"
                :max="1000"
              />
              <TextField
                :label="t('bonus_code')"
                type="text"
                :name="name.BONUS_CODE"
                v-model="formData[name.BONUS_CODE]"
                :serverError="serverError[name.BONUS_CODE]"
              />
            </section>
            <button
              class="btn casino-btn csn-dap-submit-btn"
              @click="submitForm"
              :disabled="isSubmitting"
            >
              <ButtonLoader v-if="isSubmitting" />
              <span v-else>{{ t('deposit_and_play') }}</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PAY_AND_PLAY_MODAL, Currency, RoutePath } from '@/constants'
import { remove, generateUUID } from '@/helpers'
import { formMixin, modalMixin } from '@/mixins'
import { PayAndPlayApi } from '@/api'

const name = {
  BONUS_CODE: 'BONUS_CODE',
  DEPOSIT: 'DEPOSIT',
}
const formData = {
  [name.BONUS_CODE]: '',
  [name.DEPOSIT]: 10.0,
}
const staticExampleAmountList = ['10.00', '50.00', '100.00', '500.00']

export default {
  name: PAY_AND_PLAY_MODAL,
  mixins: [formMixin, modalMixin],
  components: {
    Form: () => import('@/components/FormData'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    TextField: () => import('@/components/TextField'),
    MoneyCounter: () => import('@/components/MoneyCounter'),
  },
  data: () => ({
    formData: { ...formData },
  }),
  computed: {
    formId: () => `formID${generateUUID()}`,
    name: () => name,
    t() {
      return this.$createComponentTranslator(PAY_AND_PLAY_MODAL)
    },
    exampleAmountList: () => staticExampleAmountList,
  },
  watch: {
    formData: {
      deep: true,
      handler(formData) {
        const keys = Object.keys(this.serverError)
        const keysToRemove = []
        this.message = null

        if (!keys.length) {
          return
        }

        keys.forEach(
          (key) =>
            formData[key] !== this.formDataSubmitCopy[key] &&
            keysToRemove.push(key),
        )
        this.serverError = remove(keysToRemove)(this.serverError)
      },
    },
  },
  methods: {
    closeModal(fn) {
      return this.$emit('close', fn)
    },
    handleCloseButtonClick() {
      this.closeModal(this.goToHomepage)
    },
    handleExampleAmountClick(amount) {
      this.formData[this.name.DEPOSIT] = amount
    },
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true
      const locale = window.i18n.locale

      try {
        const request = {
          amount: Number(this.formData[name.DEPOSIT]),
          currency: Currency.EUR,
          continueUrl: `/${locale}/${RoutePath.USER_REGISTER_PROGRESS}`,
          bonusCode: this.formData[name.BONUS_CODE] || null,
        }

        const { state, msg } = await PayAndPlayApi.deposit(request)

        if (state === this.responseState.ERROR) {
          this.messageType = this.messageTypeEnum.FAILURE
          this.message = msg
          return
        }
      } catch (e) {
        console.dir(e)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>
